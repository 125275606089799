import {notifyBugsnag} from "./bugsnag/notify_bugsnag";

const ENV = process.env;
// NODE_ENV - defines production build and production code execution
export const isProduction: boolean = ENV.NODE_ENV === "production";
export const isDevelopment: boolean = ENV.NODE_ENV === "development";
if (!isProduction && !isDevelopment) {
    throw new Error("env: NODE_ENV is not properly defined");
}
// EXEC_ENV - defines execution environment (browser or server)
export const isBrowser: boolean = ENV.EXEC_ENV === "browser";
export const isServer: boolean = ENV.EXEC_ENV === "server";
if (!isBrowser && !isServer) {
    throw new Error("env: EXEC_ENV is not properly defined");
}

declare global {
    interface Window {
        __SERVER_ENV__: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const UNIVERSAL_ENV = isBrowser ? window.__SERVER_ENV__ : process.env;
// ALGOLYTICS_ID - define algolytics ID used to report events to big data. Scripts should be initialized only if this is defined.
export const algolyticsId: string | undefined = UNIVERSAL_ENV.ALGOLYTICS_ID;
// USER_COM_ID - define user.com ID. Scripts should be initialized only if this is defined.
export const userComId: string | undefined = UNIVERSAL_ENV.USER_COM_ID;
// GTM_ID - Google Tag Manager ID
export const gtmId: string = UNIVERSAL_ENV.GTM_ID;
// SmartAdServer site (prod/stage) (stage is default)
export const sasPlacementSite: string | undefined = UNIVERSAL_ENV.SAS_PLACEMENT_SITE;
// IS_TESTING - used to detect testing environment (ie. to omit Bugsnag calls)
export const isTesting: boolean = UNIVERSAL_ENV.IS_TESTING === "true";
// GH_COOKIE_DOMAIN - defined to correctly communicate cookies between gh frontend and api - api is on subdomain
export const ghCookieDomain: string = (UNIVERSAL_ENV.GH_COOKIE_DOMAIN ?? null) as string;
// ENABLE_AUTHORIZATION - allows authorization, enables KM checks etc
export const enableAuthorization: boolean = UNIVERSAL_ENV.ENABLE_AUTHORIZATION === "true";
// enable smart ad server ads
export const enableAds: boolean = UNIVERSAL_ENV.ENABLE_ADS === "true";
// enables cache ttl customization
export const enableLongCache = UNIVERSAL_ENV.ENABLE_LONG_CACHE === "true";
// enables Real User Monitoring
export const enableRUM = UNIVERSAL_ENV.ENABLE_RUM === "true";
// shows primary market offer prices on listings
export const showPrimaryMarketOfferListPrices: boolean =
    UNIVERSAL_ENV.SHOW_PRIMARY_MARKET_PRICES === "true" || UNIVERSAL_ENV.SHOW_PRIMARY_MARKET_PRICES === true;
// OVERPASS_API_URL - defines overpass API url
export const overpassApiUrl: string | null = UNIVERSAL_ENV.OVERPASS_API_URL ?? null;

export const osmTileUrl: string | null = UNIVERSAL_ENV.OSM_TILE_URL ?? null;

const isOSMMapEnabledEnv = UNIVERSAL_ENV.OSM_MAP_ENABLE === "true";
export const osmMapEnable: boolean | null = (osmTileUrl && isOSMMapEnabledEnv) || null;
if (isServer && !osmTileUrl && osmMapEnable === null) {
    notifyBugsnag({message: "OSM_MAP_ENABLE is 'true' but OSM_TILE_URL is not defined"}, "OSM_MAP_ENABLE is 'true' but OSM_TILE_URL is not defined");
}

/*
 * common URLs
 */

// GH_COMMON_URL - defines current GH site used to display full URL
export const ghCommonUrl = readRequiredEnvVariable("GH_COMMON_URL");
// API_URL - defines public GH api
export const ghApiUrl = readRequiredEnvVariable("API_URL");
// KM_URL - defines base link to Konto Mieszkaniowe
export const kmUrl: string = readRequiredEnvVariable("KM_URL");
// STAGE_HOST - defines base link to stage1
export const stageHost: string | null = UNIVERSAL_ENV.STAGE_HOST ?? null;

/*
 * Bugsnag
 */
export const buildImageTag: string = UNIVERSAL_ENV.BUILD_IMAGE_TAG;
// BUGSNAG_RELEASE_STAGE — define release stage, where only "production" and "staging" are reported
export const bugsnagReleaseStage: string = UNIVERSAL_ENV.BUGSNAG_RELEASE_STAGE ?? "development";

/*
 * Browser Env
 */
export const browserEnvs = {
    API_URL: ghApiUrl,
    GTM_ID: gtmId,
    GH_COMMON_URL: ghCommonUrl,
    BUGSNAG_RELEASE_STAGE: bugsnagReleaseStage,
    ENABLE_AUTHORIZATION: UNIVERSAL_ENV.ENABLE_AUTHORIZATION,
    ALGOLYTICS_ID: algolyticsId,
    USER_COM_ID: userComId,
    SAS_PLACEMENT_SITE: sasPlacementSite,
    ENABLE_ADS: UNIVERSAL_ENV.ENABLE_ADS,
    GH_COOKIE_DOMAIN: ghCookieDomain,
    KM_URL: kmUrl,
    STAGE_HOST: stageHost,
    SHOW_PRIMARY_MARKET_PRICES: showPrimaryMarketOfferListPrices,
    OSM_TILE_URL: osmTileUrl,
    OSM_MAP_ENABLE: UNIVERSAL_ENV.OSM_MAP_ENABLE,
    ENABLE_RUM: UNIVERSAL_ENV.ENABLE_RUM,
    OVERPASS_API_URL: overpassApiUrl
};

/**
 * Reads variable from environment. Throws if not defined
 * @param name: environment variable name
 */
function readRequiredEnvVariable(name: string): string {
    const envVariable = UNIVERSAL_ENV[name] ?? null;
    if (envVariable == null) {
        throw new Error(`env: ${name} is not defined`);
    }
    return envVariable;
}
