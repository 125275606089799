import {Dispatch} from "redux";
import {IInvestmentDetailParams} from "@web2/gh_routes";

import {fetchInvestmentDetailOverpassPoi} from "../../../app/actions/fetch_overpass_map_poi";
import {PoiType} from "../../../app/constants/PoiType";
import {IInvestmentDetailResponse} from "../../../app/interfaces/response/investment";
import {IPrevRouteState, IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {getNormalizedCoordinates} from "../../../app/utils/get_normalized_coordinates";

export const fetchInvestmentPoiAtRoute =
    (
        services: Partial<IServices>,
        route: IRouteState<IInvestmentDetailParams>,
        prevRoute: IPrevRouteState | null,
        prevActionResult: IInvestmentDetailResponse
    ) =>
    async (dispatch: Dispatch) => {
        const coordinates = getNormalizedCoordinates(prevActionResult.coordinates);

        return dispatch(
            fetchInvestmentDetailOverpassPoi(PoiType.ALL, {
                radius: 1000,
                latitude: coordinates.lat,
                longitude: coordinates.lng
            })
        );
    };
