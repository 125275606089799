import {Dispatch} from "redux";
import {opApiLink} from "@web2/ms_api_routes";

import {PoiType} from "../constants/PoiType";
import {appendQueryString} from "../utils/append_query_string";
import {consoleError} from "../utils/logger";
import {overpassApiUrl} from "../utils/read_environment_variables";
import {createRequestActionTypes, RequestActionTypes} from "../utils/request_response_utils/factories/create_request_action_types";
import {getExternalRequest} from "../utils/request_response_utils/request";
import {catch5xx, catch504, catchTimeout, catchUnknownError} from "../utils/request_response_utils/response_error";

interface IOverpassAround {
    radius: number;
    latitude: number;
    longitude: number;
}

interface IOverpassTags {
    [key: string]: any;
}

export interface IOverpassPlace {
    category: PoiType;
    dist: number;
    geom: {type: "Point"; coordinates: [number, number]};
    id: number;
    name: string;
    osm_id: number | null;
    tags: IOverpassTags;
    type: string;
}

export const fetchOverpassMapPoiTypes = createRequestActionTypes({type: "GET", name: "OverpassPOI"});
export const fetchOverpassMapPoi = createFetchOverpassMapPoi(fetchOverpassMapPoiTypes);

export const fetchInvestmentDetailOverpassPoiTypes = createRequestActionTypes({
    type: "GET",
    view: "Investment",
    name: "OverpassPOI"
});
export const fetchInvestmentDetailOverpassPoi = createFetchOverpassMapPoi(fetchInvestmentDetailOverpassPoiTypes);

export const realPoiTypes = [PoiType.EDUCATION, PoiType.TRANSPORT, PoiType.FOOD, PoiType.SPORT, PoiType.ENTERTAINMENT, PoiType.HEALTH, PoiType.SHOPS] as const;

function createFetchOverpassMapPoi(requestType: RequestActionTypes) {
    return (poiType: PoiType, around: IOverpassAround) =>
        async (dispatch: Dispatch): Promise<IOverpassPlace[] | true> => {
            const category = poiType === PoiType.ALL ? (realPoiTypes as unknown as string[]) : poiType;

            const query = {point: `${around.latitude},${around.longitude}`, dist: around.radius, category};
            const url = appendQueryString(opApiLink.geoAssets.poi.base(overpassApiUrl, null), query);

            dispatch({type: requestType.start});

            return getExternalRequest({}, url, 5000)
                .then((elements: IOverpassPlace[]) => {
                    dispatch({type: requestType.success, result: {elements}});

                    return elements;
                })
                .catch(catchTimeout((err) => handleOverpassFetchError(dispatch, err, requestType.success)))
                .catch(catch504((err) => handleOverpassFetchError(dispatch, err, requestType.success)))
                .catch(catch5xx((err) => handleOverpassFetchError(dispatch, err, requestType.success)))
                .catch(catchUnknownError((err) => handleOverpassFetchError(dispatch, err, requestType.success)));
        };
}

const handleOverpassFetchError = (dispatch: Dispatch, err: {name: string}, successRequestType: string): true => {
    consoleError("createFetchOverpassPoi error: ", err);
    dispatch({type: successRequestType, result: {elements: []}});
    return true;
};

export const resetOverpassMapPoi = () => ({type: fetchOverpassMapPoiTypes.reset});
