import * as pathToRegexp from "path-to-regexp";
import {prefixPath} from "@web2/prefix_path";

const opApiPath = prefixPath("/ms/v1/", {
    op: prefixPath("overpass/", {
        base: "",
        geoAssets: prefixPath("geo-assets/", {
            poi: prefixPath("poi/", {
                base: "",
                detail: prefixPath(":id/", {
                    routeGeometry: "route-geometry/"
                }),
                routes: "routes/"
            })
        })
    })
});

export const opApiLink = {
    op: {
        base: opApiPath.op.base
    },
    geoAssets: {
        poi: {
            base: createLink(opApiPath.op.geoAssets.poi.base),
            routeGeometry: createLink<{id: number}>(opApiPath.op.geoAssets.poi.detail.routeGeometry),
            routes: createLink(opApiPath.op.geoAssets.poi.routes)
        }
    }
};

function createLink<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);

    return (apiUrl: string | null = "", apiPathParams: TParams): string => {
        return apiUrl + compiler(apiPathParams as unknown as Record<string, string>);
    };
}
