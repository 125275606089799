import * as React from "react";
import {useCallback, useRef, useState, useTransition} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {parseUrl} from "query-string";
import {OFFER_LIST_LIST_TESTID} from "@web2/gh_page_object_models";
import {appLink, parseOfferSlugToObject} from "@web2/gh_routes";
import {PaginationWithList} from "@web2/pagination";

import {AdPlacement} from "../../../advertisement/components/AdPlacement";
import {PlacementDest} from "../../../advertisement/PlacementDest";
import {PlacementPage} from "../../../advertisement/PlacementPage";
import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {useIsLoadingUntilStop} from "../../../app/hooks/use_is_loading_until_stop";
import {useTransitioningHistoryPush} from "../../../app/hooks/use_transitioning_history_push";
import {IOfferModalDetail} from "../../../app/interfaces/response/offer_detail";
import {IOfferListOfferResponse} from "../../../app/interfaces/response/offer_list";
import {IOfferListApiResponseMeta} from "../../../app/interfaces/response/server_list_response";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {parseSearch} from "../../../app/utils/request_response_utils/parse_search";
import {validateQuery} from "../../../app/utils/request_response_utils/validate_query";
import {ApplicationModal} from "../../../application/components/ApplicationModal";
import {ApplicationSourceSection, getOfferApplicationSource} from "../../../application/utils/ApplicationSource";
import {getThemeBreakpointCorrect} from "../../../styles/linaria_variable_factory";
import {AlgolyticsSourceSection} from "../../../tracking/algolytics/interaction/application_sent_hit";
import {IGtmOffer} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {GtmSource} from "../../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../../tracking/view_type/view_type";
import {IOfferBoxOffer, OfferBox, OFFERBOX_FULL_WIDTH_BREAKPOINT, OFFERBOX_WIDTH} from "../../detail/components/offer_box/OfferBox";
import {OfferBoxSkeleton} from "../../detail/components/offer_box/OfferBoxSkeleton";
import {FullOfferApplicationModalHeader, getHousingPlatformUrl} from "../../utils/constants_offer";
import {fetchOfferListWithUniqueParams} from "../actions/fetch_offer_list";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {NoResults} from "./atoms/NoResults";
import {ArrowLeftIcon} from "./icons/ArrowLeftIcon";
import {ArrowRightIcon} from "./icons/ArrowRightIcon";
import {OfferListExtendSearch} from "./OfferListExtendSearch";
import {OfferListModalOffer} from "./OfferListModalOffer";
import {OfferListRoomsFilter} from "./OfferListRoomsFilter";

import placeholder360x337 from "../../../../assets/ad_placeholders/platforma_baner_360x337.png";

interface IProps extends RouteComponentProps {
    offersData: {
        meta: IOfferListApiResponseMeta;
        collection_count: number;
        offers: IOfferListOfferResponse[];
        requestState: RequestState;
        page: number;
        pageCount: number;
    };
    currentModalOfferSlug: string | null;
    combinedOffersList: {
        slug: string;
    }[];
    latestQuery: IOfferListQuery;
    favouriteOffers: string[];
    visitedOffers: string[];
    setFavouriteOffer: typeof setFavouriteOffer;
    isMapBig: boolean;
    onMouseEnterOfferBox: (offerId: string) => void;
    onMouseLeaveOfferBox: (offerId: string) => void;
    modalOfferRequestState: RequestState;
    onOfferClick: (e: React.MouseEvent<HTMLElement>, offer: IGtmOffer) => void;
    prevPath: string | null;
    setPrevPath: (path: string | null) => void;
    viewType: ViewType | null;
    offerModalState: boolean;
    offer: IOfferModalDetail;
    adTargeting?: string;
}

export enum PageDirection {
    NEXT,
    PREV
}

const adPlaceholder = {
    image: placeholder360x337,
    url: getHousingPlatformUrl({source: "gh_listing"})
};

const OfferListListC = (props: IProps) => {
    const {transitionHistory} = useTransitioningHistoryPush();
    const [_, startTransition] = useTransition();
    const {isLoading: isLoadingOfferList, startLoadingUntilStop: _startLoadingOfferListUntilStop} = useIsLoadingUntilStop(
        (store: IStore) => store.offerList.offers.requestState
    );
    const {isLoading: isLoadingOfferModal, startLoadingUntilStop: startLoadingOfferModalUntilStop} = useIsLoadingUntilStop(
        (store: IStore) => store.offer.offerRequestState
    );

    // to lower LCP, some images should be loaded as soon as possible,
    // use ref to prevent fade in animations until offer list was reloaded by user
    const allowImageFadeInRef = useRef(false);
    const startLoadingOfferListUntilStop = () => {
        if (!allowImageFadeInRef.current) {
            allowImageFadeInRef.current = true;
        }
        _startLoadingOfferListUntilStop();
    };

    const dispatch = useDispatch();
    const searchFormValues = useSelector((store: IStore) => store.search.formValues);
    const {rooms, offer_type, page: currentPage} = props.latestQuery;
    const [applicationOfferData, setApplicationOfferData] = useState<{
        offer: IOfferBoxOffer;
        gtmSource: GtmSource;
    } | null>(null);

    const closeAskForPriceModal = () => setApplicationOfferData(null);
    const buildPageLink = (page: number) => {
        const query = validateQuery(["page"], parseSearch(props.location.search), page >= 2 ? {page: page.toString()} : {});
        return appendQueryString(props.location.pathname, query);
    };

    const onChangePage = (page: number) => {
        if (page.toString() !== currentPage) {
            scrollToTop();
            const query = validateQuery(["page"], parseSearch(props.location.search), page >= 2 ? {page: page.toString()} : {});

            startLoadingOfferListUntilStop();
            transitionHistory(appendQueryString(props.location.pathname, query) + props.location.hash);
        }
    };

    const investmentOffers = props.offersData.offers.reduce<Record<string, string>>((acc, offer) => {
        if (offer.investment) {
            if (acc[offer.investment.id]) {
                return acc;
            }
            return {...acc, [offer.investment.id]: offer.id};
        }
        return acc;
    }, {});

    const onOfferModalClose = () => {
        if (props.prevPath) {
            const path = decodeURIComponent(props.prevPath);
            startLoadingOfferModalUntilStop();
            // store search form state in history, it can be restored when user goes `back`
            transitionHistory(path, {searchFormValues});
        }
    };

    /*
     * modal next prev offer logic
     */

    const currentModalOfferIndex = props.currentModalOfferSlug && props.combinedOffersList.findIndex((o) => o.slug === props.currentModalOfferSlug);
    const changeModalOffer = useCallback(
        (direction: PageDirection) => {
            const isDirectionNext = direction === PageDirection.NEXT;
            startLoadingOfferModalUntilStop();

            // try to show new offer
            const newOfferIndex =
                props.currentModalOfferSlug !== null &&
                Number.isFinite(currentModalOfferIndex) &&
                (currentModalOfferIndex as number) + (isDirectionNext ? 1 : -1);
            const newSlug = newOfferIndex !== null && Number.isFinite(newOfferIndex) && props.combinedOffersList[newOfferIndex as number]?.slug;

            if (newSlug) {
                newSlug && transitionHistory(appLink.fullOffer.detail.base(parseOfferSlugToObject(newSlug)));
                return;
            }

            // end of offer list page, load new page
            const shouldLoadNewPage = isDirectionNext ? props.offersData.page < props.offersData.pageCount : props.offersData.page > 0;
            if (shouldLoadNewPage && props.prevPath) {
                const newPage = props.offersData.page + (isDirectionNext ? 1 : -1);

                // update prevPath, so user can close modal to reveal updated offer list page
                const newQuery = props.prevPath ? validateQuery(["page"], parseSearch(props.prevPath), newPage >= 2 ? {page: newPage.toString()} : {}) : {};
                const {url} = parseUrl(props.prevPath);
                props.setPrevPath(appendQueryString(url, newQuery));

                // fetch new page of offer list in the background
                const params = {
                    ...props.latestQuery,
                    page: newPage.toString() // transform to string, so we can compare it correctly with search-/latestQuery
                };

                startTransition(() => {
                    dispatch(
                        fetchOfferListWithUniqueParams({}, params, (offersResponse) => {
                            // open first offer on the updated list
                            const newSlug = isDirectionNext ? offersResponse[0]?.slug : offersResponse[offersResponse.length - 1]?.slug;
                            newSlug && props.history.push(appLink.fullOffer.detail.base(parseOfferSlugToObject(newSlug)));
                        })
                    );
                });
            }
        },
        [props.offersData, props.prevPath, props.latestQuery, currentModalOfferIndex]
    );

    const renderOfferBox = useCallback(
        (off: IOfferBoxOffer, requestState: RequestState, options: {preventLazyLoad: boolean; showImagesInitially: boolean; index: number}) => {
            const {preventLazyLoad = false, showImagesInitially = true} = options;
            const isVisited = props.visitedOffers.includes(off.id);
            const isFavourite = props.favouriteOffers.includes(off.id);
            const showATag = !!(off.investment && investmentOffers[off.investment.id] && investmentOffers[off.investment.id] === off.id);

            if (requestState !== RequestState.Success || isLoadingOfferList) {
                return (
                    <ListItem order={options.index === 0 ? 1 : 2}>
                        <OfferBoxSkeleton key={off.id} />
                    </ListItem>
                );
            }

            return (
                <ListItem
                    key={off.id}
                    onMouseEnter={() => props.onMouseEnterOfferBox(off.id)}
                    onMouseLeave={() => props.onMouseLeaveOfferBox(off.id)}
                    onClick={(e) => props.onOfferClick(e, off)}
                    className={offerBoxWrapper}
                    order={options.index === 0 ? 1 : 2}
                >
                    <OfferBox
                        className={offerBoxStyle}
                        isFavourite={isFavourite}
                        isInvestment={!!off.investment}
                        isInvestmentWithHref={showATag}
                        isVisited={isVisited}
                        offer={off}
                        openApplicationModal={setApplicationOfferData}
                        setIsFavourite={props.setFavouriteOffer}
                        preventLazyLoad={preventLazyLoad}
                        loadImagesInitially={showImagesInitially}
                        allowImageFadein={!showImagesInitially}
                    />
                </ListItem>
            );
        },
        [
            props.offersData,
            props.visitedOffers,
            props.favouriteOffers,
            props.onMouseEnterOfferBox,
            props.onMouseLeaveOfferBox,
            props.setFavouriteOffer,
            isLoadingOfferList
        ]
    );

    // keep those nextPage variables. They are... occasionally helpful
    // const nextPageNumber = props.offersData.page + 1;
    // const nextPageLink = buildPageLink(nextPageNumber);
    // const noMorePages = props.offersData.page >= props.offersData.pageCount;

    const showRoomsFilter = props.latestQuery.offer_type !== "lot" && !props.offersData.meta.to_extend;
    /*
     * render
     */

    return (
        <>
            <div data-testid={OFFER_LIST_LIST_TESTID.OFFER_LIST_WRAPPER} className={offersWrapper}>
                {props.offersData.offers.length ? (
                    <ul className={offersHolder}>
                        {showRoomsFilter && (
                            <li className={roomsOrder}>
                                <OfferListRoomsFilter />
                            </li>
                        )}
                        {props.offersData.offers.map((offer, index) => {
                            return (
                                <React.Fragment key={offer.id}>
                                    {index === 2 ? (
                                        <li className={adPlacementHolder}>
                                            <AdPlacement
                                                dest={PlacementDest.MOBILE}
                                                page={PlacementPage.offer_list__mobile}
                                                className={adPlacementHolder}
                                                placeholder={adPlaceholder}
                                                breakpoint={OFFERBOX_FULL_WIDTH_BREAKPOINT}
                                                target={props.adTargeting}
                                            />
                                        </li>
                                    ) : null}
                                    {renderOfferBox(offer, props.offersData.requestState, {
                                        preventLazyLoad: index === 0,
                                        showImagesInitially: index < 6 && !allowImageFadeInRef.current,
                                        index: index
                                    })}
                                </React.Fragment>
                            );
                        })}
                        {props.offersData.meta.to_extend && <OfferListExtendSearch distance={props.offersData.meta.distance} />}
                    </ul>
                ) : (
                    <NoResults />
                )}

                {applicationOfferData && (
                    <ApplicationModal
                        offerId={applicationOfferData.offer.id}
                        modalHeader={FullOfferApplicationModalHeader.ASK_DEVELOPER_PRICE}
                        modalState={!!applicationOfferData}
                        onModalClose={closeAskForPriceModal}
                        applicationSource={getOfferApplicationSource(applicationOfferData.offer.market_type, true)}
                        applicationSourceSection={ApplicationSourceSection.MODAL}
                        algolyticsSourceSection={AlgolyticsSourceSection.BUTTON}
                        gtmSource={applicationOfferData.gtmSource}
                        viewType={props.viewType}
                        onOfferClick={props.onOfferClick}
                    />
                )}
            </div>

            <div className={paginationHolder}>
                <PaginationWithList
                    onChangePageClick={onChangePage}
                    pageCount={props.offersData.pageCount}
                    currentPage={props.offersData.page}
                    hrefBuilder={buildPageLink}
                    iconPrev={<ArrowLeftIcon />}
                    iconNext={<ArrowRightIcon />}
                    multiNumbersInside
                    showMobileVersion={props.isMapBig}
                />
            </div>

            <OfferListModalOffer
                isNext={
                    (props.offerModalState &&
                        Number.isFinite(currentModalOfferIndex) &&
                        typeof currentModalOfferIndex === "number" &&
                        currentModalOfferIndex < props.combinedOffersList.length - 1) ||
                    (props.offerModalState && props.offersData.page < props.offersData.pageCount)
                }
                isPrev={(props.offerModalState && currentModalOfferIndex && currentModalOfferIndex > 0) || (props.offerModalState && props.offersData.page > 1)}
                isLoading={
                    isLoadingOfferModal || props.modalOfferRequestState !== RequestState.Success || props.offersData.requestState !== RequestState.Success
                }
                onPageChange={changeModalOffer}
                modalState={props.offerModalState}
                onModalClose={onOfferModalClose}
                offer={{offer: props.offer}}
            />
        </>
    );
};

export const OfferListList = withRouter(OfferListListC);

/**
 * Utils
 */
// TODO: expand this functionality to be able to clear this timeout in useEffect
export const scrollToTop = () => {
    setTimeout(() => {
        try {
            window && window.scroll(0, 0);
        } catch (e) {
            // serverSide render
        }
    }, 0);
};

/**
 * Styles
 */

const offersWrapper = css`
    padding: 0;

    display: flex;
    justify-content: center;

    @media (min-width: ${OFFERBOX_FULL_WIDTH_BREAKPOINT}px) {
        padding: 1rem 0 0;
        justify-content: center;
    }
`;

const offersHolder = css`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100vw;
    grid-gap: 1.5rem 0;
    margin: -1rem 0 0 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: ${OFFERBOX_FULL_WIDTH_BREAKPOINT}px) {
        margin: 0;
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(auto-fill, ${OFFERBOX_WIDTH}px);
        width: 100%;
        justify-content: center;
    }
`;

const paginationHolder = css`
    padding: 10px 0;
`;

const adPlacementHolder = css`
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    order: 1;

    @media (min-width: ${OFFERBOX_FULL_WIDTH_BREAKPOINT}px) {
        display: none;
    }
`;

const offerBoxWrapper = css`
    max-width: 100%;
`;

const ListItem = styled.li<{order: number}>`
    order: ${(props) => props.order};
`;

const roomsOrder = css`
    order: 2;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        order: 1;
    }
`;

const offerBoxStyle = css`
    max-width: min(100vw, 360px);
`;
